import { USER_PLATFORM } from "constants/tracking";
import fetch from "isomorphic-unfetch";

import config from "../config";
import { detectDeviceOs, detectDeviceType } from "../utils";
import { PAGE_TYPES, SiteMetaData } from "../utils/constants";
import { getUrlPath } from "../utils/helpers";
import source from "../utils/source";

/* eslint-disable */
const BranchIo = {
	initialize: (branchKey) => {
		const envCheck =
			process.env.NODE_ENV !== "development" && process.env.NODE_ENV !== "test";
		if (!window.branch && branchKey && envCheck) {
			(function (b, r, a, n, c, h, _, s, d, k) {
				if (!b[n] || !b[n]._q) {
					for (; s < _.length; ) c(h, _[s++]);
					d = r.createElement(a);
					d.async = 1;
					d.src = "https://cdn.branch.io/branch-latest.min.js";
					k = r.getElementsByTagName(a)[0];
					k.parentNode.insertBefore(d, k);
					b[n] = h;
				}
			})(
				window,
				document,
				"script",
				"branch",
				function (b, r) {
					b[r] = function () {
						b._q.push([r, arguments]);
					};
				},
				{ _q: [], _v: 1 },
				"addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking".split(
					" "
				),
				0
			);
			// init Branch
			branch.init(branchKey);
		}
	},
};

export const createBranchDeepLink = async ({
	id,
	campaign = "Apna Main Website",
	customUtmMedium = PAGE_TYPES.long,
	description = undefined,
	desktopUrl = undefined,
	notificationType = "REFERRAL",
	feature = "apna-website",
	channel = "Apna Main Website",
	utm_source = "Apna Main Website",
	branchCampaign = null,
	branchStage = "Website",
	branchProps = {},
	pageType,
	section,
	router = undefined,
	userPlatform = USER_PLATFORM.apna,
	utm_medium = router?.query?.utm_medium || source(),
	utm_campaign = router?.query?.utm_campaign || source(),
}) => {
	const final_utm_source = router?.query?.utm_source || utm_source;
	const primaryUrl = `https://play.google.com/store/apps/details?id=com.apnatime&ref=w&utm_source=${final_utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}`;

	let user_id = undefined;
	if (typeof window !== "undefined") {
		user_id = localStorage.getItem("web_user_id");
	}
	const paramsObj = {
		branch_key: config.branchIO,
		"~channel": "apna_main_website", // source
		"~feature": pageType || feature, // medium utm
		stage: branchStage,
		"~campaign": section || branchCampaign || campaign, // campaign - change for each section click
		data: {
			$canonical_identifier: "apna/share_post",
			referrer_id: id,
			notification_type: notificationType,
			$desktop_url: desktopUrl || primaryUrl,
			$og_title: "Check Out Apna For Finding Jobs Around You",
			$og_description: description || SiteMetaData.description,
			$og_image_url: SiteMetaData.imageFullPath,
			$publicly_indexable: "true",
			source: "Website",
			$android_url: primaryUrl,
			$ios_url: desktopUrl || primaryUrl,
			$ipad_url: desktopUrl || primaryUrl,
			$pageType: pageType,
			$pageUrl: getUrlPath(),
			$section: section,
			"~channel": channel, // source
			"~feature": feature, // medium utm
			stage: branchStage,
			"~campaign": branchCampaign || campaign,
			deviceOS: detectDeviceOs(),
			deviceType: detectDeviceType(),
			userAgent: typeof window !== "undefined" && window?.navigator?.userAgent,
			trafficChannel: source(),
			userPlatform,
			...branchProps,
		},
	};

	if (user_id) {
		paramsObj.data.web_user_id = user_id;
	}

	try {
		const deepLink = await fetch(`https://api2.branch.io/v1/url`, {
			method: "POST",
			body: JSON.stringify(paramsObj),
		});

		const deepLinkResponse = await deepLink.json();

		return deepLinkResponse.url;
	} catch (error) {
		return primaryUrl;
	}
};

export default BranchIo;
