// eslint-disable-next-line import/prefer-default-export
export const URL_PARAMS = "URL_PARAMS";

export const URL_PARAM_KEYS = [
	"utm_medium",
	"utm_campaign",
	"utm_source",
	"utm_adset",
	"utm_content",
	"utm_custom_1",
	"utm_custom_2",
	"matchtype",
	"gclid",
	"utm_term",
];
