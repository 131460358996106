import { URL_PARAMS } from "constants/utmTracking/utmTracking";

const getTrackingParams = (
	getParams: () => Record<string, any> = null,
	asObj = false
) => {
	try {
		if (getParams) {
			const query = getParams();

			if (asObj) {
				return query || {};
			}
			if (query) {
				const queryString = Object.keys(query)
					.filter((key) => key !== "page")
					.map((key) => `${key}=${query[key]}`)
					.join("&");
				return queryString ? `?${queryString}` : "";
			}
			return "";
		}
		if (localStorage.getItem(URL_PARAMS)) {
			try {
				// URL PARAMS is a JSON string if its not we will catch error and return null
				const query = JSON.parse(localStorage.getItem(URL_PARAMS));

				if (asObj) {
					return query || {};
				}
				const queryString = Object.keys(query)
					.map((key) => `${key}=${query[key]}`)
					.join("&");

				const params = queryString.substring(1)
					? `?${queryString.substring(1)}`
					: "";
				return params;
			} catch (error) {
				return "";
			}
		}
		return "";
	} catch (e) {
		return "";
	}
};

export default getTrackingParams;
